<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_transfer_evaluation')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('course_transfer_evaluation')" :isFilter="false" />
            </template>
            <div class="row mb-5 align-items-center" v-if="data != null">
                <div class="col-12">
                    <div class="row border mb-2 p-2">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('student_number')">
                                {{ data.student_number }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('name_surname')">
                                {{ data.name }} {{ data.surname }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('program')">
                                {{ this.getLocaleText(data, 'program_name') }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('class')">
                                {{ this.getLocaleText(data, 'class_name') }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('registration_type')">
                                {{ this.getLocaleText(data, 'registration_type') }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('number_of_semesters')">
                                {{ data.number_of_semesters }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('cgpa')">
                                {{ data.cgpa }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('calculated_cgpa')">
                                {{ data.calculated_cgpa }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <b-form-group :label="$t('student_explanation')">
                                <span :class="['text-danger font-weight-bold']" :style="`font-size: 1.2rem;`">
                                    {{ data.student_explanation || "-" }}
                                </span>
                            </b-form-group>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4 mt-3 mb-3 mb-md-0" v-if="data && files.length > 0">
                    <h6 class="mb-4">{{ $t('documents').toUpper() }}</h6>
                    <table class="table table-bordered">
                        <tr>
                            <th>{{ $t('id').toUpper() }}</th>
                            <th>{{ $t('university').toUpper() }}</th>
                            <th>{{ $t('country').toUpper() }}</th>
                            <th>{{ $t('files').toUpper() }}</th>
                        </tr>
                        <tr v-for="file in files" v-bind:key="file.id">
                            <td>{{file.id}}</td>
                            <td>{{ getLocaleText(file.university, 'name') }}</td>
                            <td>{{ getLocaleText(file.university, 'country_name') }}</td>
                            <td>
                                <a href="javascript:void(0)" style="margin-right: 1em" @click="download(ffile)"
                                    v-for="ffile in file.files" v-bind:key="ffile.id">{{ ffile.file_name }}</a>
                                <div v-if="file.university_id == data.university_id && data.all_programs">
                                    <a href="javascript:void(0)" style="margin-right: 1em"
                                        @click="downloadTranscript(program.id)" v-for="program in data.all_programs"
                                        v-bind:key="program.id">{{
                    program.program.name
                }} {{ $t('transcript') }}</a>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-12 col-md-8 p-3 border-1 border" v-html="informationText"></div>
            </div>
            <div v-if="slots != null">
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="mb-4">{{ $t('courses').toUpper() }}</h6>
                    <b-button @click="excel" variant="success" class="py-3 mb-2 " style="line-height: 15px;">
                        {{ $t('excel') }}
                    </b-button>
                </div>
                <table class="table table-bordered">
                    <tr>
                        <th>{{ $t('slot_type').toUpper() }}</th>
                        <th class="width-100">{{ $t('course_code').toUpper() }}</th>
                        <th>{{ $t('course_name').toUpper() + ' / ' + $t('elective_pool').toUpper() }}</th>
                        <th class="w-40">{{ $t('credit').toUpper() }}</th>
                        <th class="w-40">{{ $t('ects_credit').toUpper() }}</th>
                        <th class="w-40">{{ $t('semester').toUpper() }}</th>
                        <th class="w-40">{{ $t('grade').toUpper() }}</th>
                        <th class="w-40" v-if="allowEdit">{{ $t('action_title').toUpper() }}</th>
                        <th style="width: 50%;text-align: center">{{ $t('added_course_transfer').toUpper() }}</th>
                    </tr>

                    <tr v-for="slot in slots" v-bind:key="slot.id">
                        <td>{{ slot.slot_type ? getLocaleText(slot.slot_type, 'name') : "" }}</td>
                        <td>{{ slot.course ? slot.course.code : '' }}</td>
                        <td>{{
                    slot.course ? getLocaleText(slot.course, 'name') : (slot.pool ? getLocaleText(slot.pool,
                        'name')
                        : '')
                }}
                        </td>
                        <td>{{ slot.credit }}</td>
                        <td>{{ slot.ects_credit }}</td>
                        <td>{{ slot.semester }}</td>
                        <td><span v-if="slot.student_courses.length">{{ slot.student_courses[0].letter_grade }}</span>
                        </td>
                        <td v-if="allowEdit">
                            <a v-if="!slot.student_courses.length || data.cgpa < 2 || (parseFloat(slot.student_courses[0].point) < 2 || slot.student_courses[0].letter_grade == 'W' || slot.student_courses[0].letter_grade=='E')"
                                role="button" class="ri-add-circle-line ri-2x" @click="addCourse(slot)"></a>
                        </td>
                        <td>
                            <div v-if="transferredCourses != null && transferredCourses[slot.id]">
                                <p v-for="course in transferredCourses[slot.id]" v-bind:key="course.id">
                                    <span v-if="course.university_course">
                                        <b>{{ $t('country') }}:</b> {{ course.university_course.country_name }}
                                        <b> {{ $t('university') }}:</b> {{ course.university_course.university_name
                                        }}<br>
                                        <b>{{ $t('course_code') }}:</b> {{ course.university_course.code }}
                                        <b>{{ $t('course_name') }}:</b> {{ course.university_course.name }} <b>
                                            {{ $t('grade') }} :</b> {{ course.letter_grade }}
                                        ({{ course.converted_letter_grade }})
                                        <b>{{ $t('credit') }}:</b> {{ course.credit }} <b>{{
                    $t('ects_credit')
                }}:</b> {{ course.ects_credit }}
                                    </span>
                                    <span v-if="course.course">
                                        <b>{{ $t('course_code') }}:</b> {{ course.course.code }} <b>
                                            {{ $t('course_name') }}:</b> {{ course.course.name }} <b>
                                            {{ $t('grade') }}:</b> {{ course.letter_grade }}
                                        <b>{{ $t('credit') }}:</b> {{ course.credit }} <b>
                                            {{ $t('ects_credit') }}:</b> {{ course.ects_credit }}
                                    </span>

                                    <a v-if="allowEdit" role="button" class="text-danger btn-link btn-xs"
                                        @click="deleteCourse(course.id)">
                                        {{ $t('delete').toUpper() }}
                                    </a>
                                </p>
                            </div>
                            <div v-else-if="slot.student_courses && slot.student_courses.length" style="color: red">
                                <p v-for="course in slot.student_courses">
                                    <b>{{ $t('previous_course_code') }}:</b> {{ course.course_code }} <b>
                                        {{ $t('previous_course_name') }}:</b> {{ course.course_name }} <b>
                                        {{ $t('grade') }}:</b> {{ course.letter_grade }}
                                    <b>{{ $t('credit') }}:</b> {{ course.credit }} <b>
                                        {{ $t('ects_credit') }}:</b> {{ course.ects_credit }}
                                    <b>{{ $t('period') }}:</b> {{ course.semester.academic_year+' '+printSemester(course.semester.semester) }}
                                </p>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="mb-3 d-inline-block"></div>
            <div v-if="data && !data.read_only && data.step.id">
                <h6 class="text-uppercase mb-4">{{ $t('approval_procedures').toUpper() }}</h6>
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                            <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('status')">
                                    <template>
                                        <div>
                                            <multiselect placeholder="Yok" v-model="updateForm.status"
                                                :options="computeStatusOptions" :custom-label="statusOptionsCustomLabel"
                                                :select-label="''" :selected-label="''" :deselect-label="''"
                                                :searchable="false">
                                            </multiselect>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]">
                                            </div>
                                        </div>
                                    </template>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-9">
                            <ValidationProvider name="explanation" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('explanation')">
                                    <b-form-textarea no-resize rows="4" class="h-40 h-auto-sm" maxlength="100"
                                        v-model="updateForm.reason"></b-form-textarea>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6" v-if="updateForm.status == 'waiting_info'">
                            <div class="row">
                                <div class="col-12">
                                    <b-form-group :label="$t('from_where')">
                                        <multi-selectbox :multiple="false" v-model="updateForm.info_from"
                                            :options="[{ value: 'unit', text: $t('unit') }, { value: 'program', text: $t('program') }]"></multi-selectbox>
                                    </b-form-group>
                                </div>
                                <div class="col-12" v-if="updateForm.info_from == 'unit'">
                                    <ValidationProvider name="unit_ids" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('units')">
                                            <units-selectbox v-model="updateForm.unit_ids"
                                                :validate-error="errors[0]"></units-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12" v-if="updateForm.info_from == 'program'">
                                    <div class="row">
                                        <div class="col-12">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox :multiple="true"
                                                    v-model="updateForm.faculty_code"></faculty-selectbox>
                                            </b-form-group>
                                        </div>
                                        <div class="col-12">
                                            <ValidationProvider name="department_code" rules="required"
                                                v-slot="{ valid, errors }">
                                                <b-form-group :label="$t('program')">
                                                    <department-selectbox :multiple="true"
                                                        :is_faculty_code_required="true"
                                                        :faculty_code="updateForm.faculty_code"
                                                        v-model="updateForm.department_codes"
                                                        :validate-error="errors[0]"></department-selectbox>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-form-group>
                        <b-button variant="primary" @click="updateStatus">{{
                    $t('update').toUpper()
                }}
                        </b-button>
                    </b-form-group>
                </ValidationObserver>
            </div>

            <div class="row mb-3" v-if="data && data.status == 'pending' && checkPermission('coursetransfer_complete')">
                <div class="col-12">
                    <b-button variant="primary" @click="complete">{{
                    $t('complete').toUpper()
                }}
                    </b-button>
                </div>
            </div>
            <div v-if="data != null && data.steps && data.steps.length">
                <b-table :empty-text="$t('there_are_no_records')" bordered striped responsive :items="data.steps"
                    :fields="[
                    { key: 'rank', label: this.toUpperCase('rank'), thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'approvers', label: this.toUpperCase('can_approvers'), thClass: 'text-center' },
                    { key: 'approved', label: this.toUpperCase('approved_by'), thClass: 'text-center' },
                    { key: 'status', label: this.toUpperCase('status'), thClass: 'text-center' },
                    { key: 'reason', label: this.toUpperCase('explanation'), thClass: 'text-center' },
                    { key: 'student_explanation', label: this.toUpperCase('student_explanation'), thClass: 'text-center', tdClass: 'text-center' },
                           { key: 'buttons', label: '', tdClass:'width-200'}
                ]" show-empty sort-by="rank" class="mb-3 table-dropdown no-scrollbar border rounded">
                    <template #cell(approvers)="row">
                        <span v-for="(approver, i) in row.item.approvers" :key="i">
                            {{
                    typeof approver.surname != 'undefined' ? (approver.name + ' ' + approver.surname) :
                        getLocaleText(approver, 'explanation')
                }} <br>
                        </span>
                    </template>
                    <template #cell(approved)="row">
                        <span v-for="(approver, i) in row.item.approved" :key="i">
                            {{ approver.name }} {{ approver.surname }}<br>
                        </span>
                    </template>
                    <template #cell(status)="row">
                        {{ getLocaleText(row.item, 'status_name') }}
                    </template>
                    <template #cell(student_explanation)="row">
                        {{row.item.student_explanation}}
                    </template>
                    <template #cell(buttons)="row">
                        <div class="d-flex">
                            <b-button variant="outline-success"
                                      size="xs"
                                      v-if="row.item.allow_set_active==true && checkPermission('approvalstep_setactive')"
                                      @click="setActive(row.item.id)">
                                {{ $t('set_as_active_step').toUpper() }}
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </div>

            <CommonModal ref="courseModal" size="lg" v-on:onHidden="onCourseFormHidden" v-on:onShow="onCourseFormShow">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_course') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="courseForm">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="university_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('university')">
                                        <b-form-select v-model="form.university_id" :options="universities">

                                        </b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12"
                                v-if="data && form.university_id && form.university_id != data.university_id">
                                <ValidationProvider name="grade_conversion_id" rules="required"
                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('grade_scala') }}</label>
                                        <multi-selectbox :multiple="false" v-model="form.grade_conversion_id"
                                            :options="gradeConversionOptions"></multi-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12"
                                v-if="data && form.university_id && form.university_id != data.university_id">
                                <ValidationProvider name="university_course_id" rules="required"
                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('course')">
                                        <div class="d-flex">
                                            <university-course-auto-complete ref="universityCourse" class="w-100"
                                                                             v-model="form.university_course_id"
                                                                             :university-id="form.university_id"
                                                                             :course-id="selectedSlot.course_id"
                                                                             v-on:courseSelected="onUniversityCourseSelected" :validateError="errors[0]">

                                            </university-course-auto-complete>
                                            <b-button variant="link" @click="$bvModal.show('newCourseModal')"
                                                style="white-space: nowrap">
                                                {{ $t('add_course') }}
                                            </b-button>
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12"
                                v-if="data && form.university_id && form.university_id == data.university_id">
                                <ValidationProvider name="course_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('course')">
                                        <course-auto-complete v-model="form.course_id"
                                            v-on:courseSelected="courseSelected" :validateError="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4"
                                v-if="data && form.university_id && form.university_id != data.university_id">
                                <ValidationProvider name="letter_grade" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('grade') }}</label>
                                        <b-form-input v-model="form.letter_grade"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4"
                                v-if="data && form.university_id && form.university_id == data.university_id">
                                <ValidationProvider name="grading_system_id" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('grading_system')">
                                        <grade-system-selectbox :grading_system_id="form.grading_system_id"
                                            v-model="form.grading_system_id" :validateError="errors[0]" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4"
                                v-if="data && form.university_id && form.university_id == data.university_id">
                                <ValidationProvider name="letter_grade" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('grade') }}</label>
                                        <grade-selectbox :grading_system_id="form.grading_system_id" value-type="grade"
                                            v-model="form.letter_grade" :validateError="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="credit" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('credit') }}</label>
                                        <b-form-input type="number" v-model="form.credit"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="ects_credit" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('ects_credit') }}</label>
                                        <b-form-input type="number" v-model="form.ects_credit"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <ValidationProvider name="semester_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('semester') }}</label>
                                        <semesters-selectbox :setActive="true" :validate-error="errors[0]"
                                            v-model="form.semester_id"></semesters-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6" v-if="data && form.university_id && form.university_id != data.university_id">
                                <ValidationProvider name="equivalent_course_id" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('equivalent_course') }}</label>
                                        <course-auto-complete :validate-error="errors[0]"
                                                              v-on:courseSelected="equivalentCourseSelected"
                                                             v-model="form.equivalent_course_id"></course-auto-complete>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveCourse" variant="primary">{{ $t('save') }}</b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="newCourseModal" size="xl" :onHideOnlyX="true" :footer="false"
                :footer-class="'justify-content-center'">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_new_course').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="formNewCourse">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('course_name')">
                                            <b-form-input type="text" v-model="formNewCourse.name"
                                                :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="name_tr" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('course_name') + '(' + $t('turkish') + ')'">
                                            <b-form-input type="text" v-model="formNewCourse.name_tr"
                                                :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="name_en" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('course_name') + '(' + $t('english') + ')'">
                                            <b-form-input type="text" v-model="formNewCourse.name_en"
                                                :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('course_code')">
                                            <b-form-input type="text" v-model="formNewCourse.code"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                @keypress="event => event.key === '*' && event.preventDefault()">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <ValidationProvider name="university_id" rules="required"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('university_where_the_course_was_taken')">
                                            <b-form-select v-model="formNewCourse.university_id" :options="universities"
                                                :class="errors[0] ? 'is-invalid' : ''">
                                            </b-form-select>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="language" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('course_language')">
                                            <parameter-selectbox code="languages" v-model="formNewCourse.language"
                                                :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <b-form-group>
                                        <b-button variant="primary" @click="saveNewCourse">{{ $t('save') }}</b-button>
                                    </b-form-group>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import CommonModal from "@/components/elements/CommonModal";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import UnitsSelectbox from "@/components/interactive-fields/UnitsSelectbox";

// Services
import ApprovalStepService from "@/services/ApprovalStepService"
import StudentSlotService from "@/services/StudentSlotService"
import CourseTransferService from "@/services/CourseTransferService"
import UniversityService from "@/services/UniversityService"
import ConstantService from "@/services/ConstantService"

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";
import UniversityCourseService from "@/services/UniversityCourseService";
import StudentDocumentService from "@/services/StudentDocumentService";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox";
import ProgramSelectbox from "../../../components/interactive-fields/ProgramSelectbox";
import FacultySelectbox from "../../../components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "../../../components/interactive-fields/ParameterSelectbox";
import qs from "qs";
import UniversityCourseAutoComplete from "@/components/interactive-fields/UniversityCourseAutoComplete.vue";


export default {
    components: {
        UniversityCourseAutoComplete,
        ParameterSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        GradeSelectbox,
        CourseAutoComplete,
        MultiSelectbox,
        AppLayout,
        Header,
        HeaderMobile,

        CommonModal,
        SemestersSelectbox,
        UnitsSelectbox,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            id: this.$route.params.id,
            form: {},
            formNewCourse: {},
            updateForm: {},
            data: null,
            slots: null,
            transferredCourses: null,
            files: [],
            universities: [{ text: this.translate('select'), value: 0 }],
            debounceMilliseconds: 200,
            inputProps: {
                id: "autosuggest__input",
                placeholder: this.$t('search') + "...",
                class: "form-control"
            },
            courseQuery: "",
            courses: [],
            sectionConfigs: {
                limit: 10,
                default: {
                    onSelected: (item) => {
                        this.form.university_course_id = item.item.id;
                    }
                }
            },
            selectedSlot: null,
            gradeConversionOptions: [],
            allowEdit: false,
            selectedUniversity: null,
            selectedGradeConversion: null,
            informationText: null
        }
    },
    metaInfo() {
        return {
            title: this.$t("course_transfer_evaluation")
        }
    },
    created() {
        this.loadData()
        this.loadFiles()
        this.getInformationText()
    },
    methods: {
        setActive(id){
            this.$swal.fire({
                text: this.$t('are_you_sure_to_set_active'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            }).then((result) => {
                if (result.isConfirmed) {
                    CourseTransferService.setActive(id).then(response=>{
                        this.showMessage(response);
                        this.loadData();
                    }).catch(e=>{
                        this.showErrors(e);
                    });
                }
            });
        },
        excel() {
            const excelData = this.slots.map(item => {
                const transferCourse = this.transferredCourses[item.id]
                const resData = {}
                if (this.transferredCourses && transferCourse) {
                    resData.transferInfo = ""
                    transferCourse.forEach((i, index) => {

                        if (i.university_course) {

                            resData.transferInfo += `${index + 1}* ${this.$t('country')}: ${i?.university_course?.country_name}-${this.$t('university')}: ${i?.university_course?.university_name}-${this.$t('course_code')}: ${i?.university_course?.code}-${this.$t('course_name')}: ${i?.university_course?.name}-${this.$t('grade')}: ${i?.letter_grade}-${this.$t('credit')}:${i?.credit}-${this.$t('ects_credits')}:${i?.ects_credit}
                            `
                        } else if (i.course) {
                            resData.transferInfo += `${index + 1}* ${this.$t('course_code')}: ${i?.course.code}-${this.$t('course_name')}: ${this.getLocaleText(i.course, "name")}-${this.$t('grade')}: ${i?.letter_grade}-${this.$t('credit')}: ${i?.credit}-${this.$t('ects_credit')}: ${i?.ects_credit}
                            `
                        }
                    })
                }
                return {
                    ...resData,
                    slotTypeName: this.getLocaleText(item.slotType, "name"),
                    courseCode: item?.course?.code,
                    courseName: item.course ? this.getLocaleText(item.course, 'name') : item.pool ? this.getLocaleText(item.pool, 'name') : '',
                    credit: item.credit,
                    ects_credit: item.ects_credit,
                    semester: item.semester,
                    letter_grade: item?.student_courses[0]?.letter_grade,
                }
            })

            // excelData.unshift({})
            // excelData.unshift({
            //     slotTypeName: `${this.$t('student_number_long')}: ${this.data.student_number}`,
            //     courseCode: `${this.$t('name_surname')}: ${this.data.name + " " + this.data.surname}`,
            //     courseName: `${this.$t('program')}: ${this.getLocaleText(this.data, 'program_name')}`,
            //     credit: `${this.$t('class')}: ${this.getLocaleText(this.data, 'class_name')}`,
            // })
            // excelData.unshift({})

            const headers = [
                { header: this.$t('slot_type'), key: "slotTypeName", width: 20 },
                { header: this.$t('course_code'), key: "courseCode", width: 20 },
                { header: `${this.$t('course_name') + ' / ' + this.$t('elective_pool').toUpper()}`, key: "courseName", width: 23 },
                { header: `${this.$t('credit')}`, key: "credit", width: 15 },
                { header: this.$t('ects_credit'), key: "ects_credit", width: 20 },
                { header: this.$t('semester'), key: "semester", width: 20 },
                { header: this.$t('grade'), key: "letter_grade", width: 20 },
                { header: this.$t('added_course_transfer'), key: "transferInfo", width: 20, height: 10 },
            ]
            this._downloadExcelFile({ data: excelData, filename: `${this.$t('adaptation_request').toLowerCase()}-${this.$t('excel').toLowerCase()}.xlsx`, headers })

        },
        loadData(onlyData = false) {
            CourseTransferService.get(this.id)
                .then(response => {
                    this.data = response.data.data;
                    this.allowEdit = !['approved', 'declined'].includes(this.data.status)
                    this.data.steps=response.data.data.steps.map((item) => {
                         return {
                             ...item,
                             student_explanation:this.data.student_explanation
                         }
                    })
                    if (this.data.step) {
                        this.updateForm = {
                            id: this.data.step.id,
                            status: this.data.step.status,
                            reason: this.data.step.reason,
                        };
                    }
                    if (!onlyData) {
                        this.loadSlots();
                    }
                }).catch(error => {
                    if (error.data.message) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
        loadSlots() {
            StudentSlotService.getByStudentProgramId(this.data.student_program_id, { params: { with_courses: true } })
                .then(response => {
                    this.slots = response.data.data;
                    this.loadCourses();
                }).catch(error => {
                    if (error.data.message) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
        loadCourses() {
            CourseTransferService.getCourses(this.id)
                .then(response => {
                    this.transferredCourses = this.groupBy(response.data.data, 'student_slot_id');
                }).catch(error => {
                    if (error.data.message) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
        loadFiles() {
            this.files = [];
            CourseTransferService.getFiles(this.id)
                .then(response => {
                    response.data.data.forEach(row => {
                        this.universities.push({
                            value: row.university.id,
                            text: this.getLocaleText(row.university, 'name')
                        });
                        CourseTransferService.getFile(row.id).then(fr => {
                            this.files.push(fr.data.data);
                        })

                        if (this.universities.length == 1) {
                            this.form.university_id = this.universities[0].value;
                            this.formNewCourse.university_id = this.universities[0].value;
                            this.selectedUniversity = this.universities[0].value;
                        }
                    });
                }).catch(error => {
                    if (error.data.message) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
        download(file) {
            CourseTransferService.download(file.uuid)
                .then(response => {
                    this._downloadFile(response, file.file_name);
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
        downloadTranscript(id) {
            StudentDocumentService.downloadTranscript(id, this.$i18n.locale)
                .then(response => {
                    this._downloadFile(response, this.data.student_number + '-' + this.$t('file_name_transcript') + '.pdf')
                })
                .catch(e => {
                    this.showErrors(e, null, true)
                })
        },
        loadGradeConversions() {
            this.gradeConversionOptions = [];
            if (!this.form.university_id) {
                return;
            }
            this.selectedGradeConversion = null;
            UniversityService.getUniversityGradeConversions(this.form.university_id)
                .then(response => {
                    response.data.data.forEach(item => {
                        this.gradeConversionOptions.push({
                            value: item.grade_conversion_id,
                            text: item.grade_conversion_name
                        });
                    });
                    if (this.gradeConversionOptions.length == 1) {
                        this.selectedGradeConversion = this.gradeConversionOptions[0].value;
                        this.form.grade_conversion_id = this.gradeConversionOptions[0].value;
                    }
                    if (this.gradeConversionOptions.length === 0) {
                        this.$toast.error(this.$t('cannot_find_grade_conversion'));
                    }
                }).catch(error => {
                    if (error.data.message) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
        async store() {
            if (this.checkPermission('coursetransfer_store')) {
                const isValid = await this.$refs.storeForm.validate();
                if (isValid) {
                    CourseTransferService.store(this.form)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.form = response.data.data;
                        })
                        .catch(e => {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        complete() {
            this.$swal.fire({
                text: this.$t('are_you_sure_you_want_to_complete_course_transfer'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            }).then((result) => {
                if (result.isConfirmed) {
                    CourseTransferService.complete(this.data.id)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(error => {
                            this.showErrors(error);
                        });
                }
            })
        },
        onCourseFormHidden() {
            this.form = {
                university_id: this.selectedUniversity,
            };
            this.courseQuery = "";
            this.courses = [];
        },
        onCourseFormShow(){
            if(this.form.university_id){
                this.fetchResults(true);
            }
        },
        addCourse(slot) {
            this.selectedSlot = slot;
            this.$refs.courseModal.$refs.commonModal.show()
        },
        async saveCourse() {
            if (this.checkPermission('coursetransfercourse_store')) {
                const isValid = await this.$refs.courseForm.validate();

                let data = {
                    course_transfer_id: this.id,
                    student_slot_id: this.selectedSlot.id,
                    university_course_id: this.form.university_course_id,
                    course_id: this.form.course_id,
                    equivalent_course_id: this.form.equivalent_course_id,
                    semester_id: this.form.semester_id,
                    credit: this.form.credit,
                    ects_credit: this.form.ects_credit,
                    letter_grade: this.form.letter_grade,
                    grade_conversion_id: this.form.grade_conversion_id,
                    grading_system_id: this.form.grading_system_id
                };
                if (isValid) {
                    CourseTransferService.addCourse(data).then(response => {
                        this.loadCourses();
                        this.loadData(true);
                        this.form = {
                            university_id: this.selectedUniversity,
                            grade_conversion_id: this.selectedGradeConversion
                        };
                        this.$refs.courseForm.reset();
                        this.selectedSlot = null;
                        this.$refs.courseModal.$refs.commonModal.hide();
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                        .catch(e => {
                            if (e.status !== undefined && e.data.message) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        deleteCourse(id) {
            if (this.checkPermission('coursetransfercourse_delete')) {
                this.deleteModal(() => {
                    CourseTransferService.deleteCourse(id)
                        .then(response => {
                            this.loadCourses();
                            this.loadData(true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(error => {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        });
                });
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        getSuggestionValue(suggestion) {
            let { item } = suggestion;
            return item.code + ' - ' + this.getLocaleText(item, 'name');
        },
        fetchResults(all=false) {
            let query = this.courseQuery;
            if(!all){
                if (query.length < 3 || !this.form.university_id) {
                    return;
                }
            }
            else {
                query = '...';
            }
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                const config = {
                    params: {
                        key: query,
                        university_id: this.form.university_id,
                        course_id: this.selectedSlot.course_id
                    }
                };
                UniversityCourseService.search(config).then((response) => {
                    this.courses = response.data.data;
                });

            }, this.debounceMilliseconds);
        },
        async updateStatus() {
            if (this.checkPermission('approvalstep_updatestatus')) {
                const isValid = await this.$refs.updateForm.validate();
                if (isValid) {
                    this.$swal.fire({
                        text: this.$t('are_you_sure_you_want_to_update_the_approval_status'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no'),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            ApprovalStepService.update(this.updateForm.id, this.updateForm)
                                .then(response => {
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                })
                                .catch(error => {
                                    if (error.status == 422) {
                                        if (error.data.errors.status) {
                                            this.$refs.updateForm.errors.status.push(error.data.errors.status[0]);
                                        }
                                        if (error.data.errors.reason) {
                                            this.$refs.updateForm.errors.explanation.push(error.data.errors.reason[0]);
                                        }
                                    }
                                    if (error.status == 406) {
                                        this.$toast.error(this.$t('api.' + error.data.message));
                                    }
                                });
                        }
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        statusOptionsCustomLabel(key) {
            let label = key;
            if (this.data.step.statuses[key] != undefined) {
                label = this.$i18n.locale == 'tr' ? this.data.step.statuses[key].name : this.data.step.statuses[key].name_en;
                return label;
            }
            return '';
        },
        async saveNewCourse() {
            if (this.checkPermission('universitycourse_store')) {
                const isValid = await this.$refs.formNewCourse.validate();
                if (isValid) {
                    let formData = {
                        ...this.formNewCourse
                    }
                    UniversityCourseService.store(formData)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$refs.formNewCourse.reset();
                            this.$bvModal.hide('newCourseModal');
                            this.formNewCourse = { university_id: this.selectedUniversity };
                        })
                        .catch(e => {
                            this.showErrors(e)
                        })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        courseSelected(course) {

            this.$set(this.form, 'course_id', course.item.course_id);
            this.$set(this.form, 'credit', course.item.credit);
            this.$set(this.form, 'ects_credit', course.item.ects_credit);
            this.$set(this.form, 'grading_system_id', course.item.grading_system_id);
        },

        equivalentCourseSelected(course) {
            this.$set(this.form, 'equivalent_course_id', course.item.course_id);
        },
        onUniversityCourseSelected(item){
            this.form.university_course_id = item.item.id;
        },

        getInformationText() {
            const config = {
                params: {
                    'key': 'course_transfers_info'
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }
            ConstantService.getValue(config).then(response => {
                this.informationText = response.data.data
            }).catch(e => {
                this.showErrors(e, null)
            })
        },

        printSemester(data){
            switch (data){
                case '1': return this.$t('fall')
                case '2': return this.$t('spring')
                case '3': return this.$t('summer')
                case '4': return this.$t('yearly')
                default: return ''
            }
        }
    },
    computed: {
        suggestions() {
            return [
                {
                    data: this.courses.filter(option => {
                        let str = option.code + ' - ' + option.name
                        if(typeof option.matched_count != 'undefined'){
                            str += ' ('+option.matched_count+')'
                        }
                        return str;
                    })
                }
            ];
        },
        computeStatusOptions() {
            let options = [];
            if (this.data.step.statuses) {
                for (const item in this.data.step.statuses) {
                    options.push(item);
                }
            }
            return options;
        }
    },
    watch: {
        'form.university_id'() {
            if (this.data.university_id != this.form.university_id) {
                this.loadGradeConversions();
                setTimeout(() => {
                    this.$refs.universityCourse.search('...');
                }, 300);
            }
            this.selectedUniversity = this.form.university_id;
            this.formNewCourse.university_id = this.selectedUniversity;
        },
        'form.grade_conversion_id'() {
            this.selectedGradeConversion = this.form.grade_conversion_id;
        }
    }
};
</script>
